import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FormError from '~/utilities/FormError';
import GlobalFormMessagePattern, {
	GlobalFormMessagePatternStyle,
} from '~/components/atoms/forms/basis/GlobalFormMessagePattern';
import GoogleApi from '~/components/logic/GoogleApi';
import GoogleSignInButton from '~/components/app/GoogleSignInButton';

import {
	useLoginWithGoogleMutation,
} from './LoginWithGoogleForm.gql';

import useHandleSuccessfulLogin from '~/hooks/useHandleSuccessfulLogin';

import {
	redirectToConductorLogin,
} from '~/model/conductor';

import {
	LoginScreenView,
} from '~/model/loginScreen';



const messages = defineMessages({
	invalidIdToken: {
		id: 'ui.loginWithGoogleForm.errors.invalidIdToken',
	},
	userNotFound: {
		id: 'ui.loginWithGoogleForm.errors.userNotFound',
	},
});



type Props = {
	onLoginFinish?: (success: boolean) => void,
	onLoginStart?: () => void,
	setView: (view: LoginScreenView) => void,
};

const LoginWithGoogleForm: React.FC<Props> = (props) => {
	const {
		onLoginFinish,
		onLoginStart,
		setView,
	} = props;

	const handleSuccessfulLogin = useHandleSuccessfulLogin();

	const [error, setError] = React.useState<FormError | null>(null);

	const [loginWithGoogleMutation] = useLoginWithGoogleMutation();

	const handleLoginWithGoogleForm = React.useCallback(
		async (values) => {
			try {
				setError(null);

				onLoginStart?.();

				const idToken = values.credential;

				await loginWithGoogleMutation({
					variables: {
						idToken,
					},
				});

				await handleSuccessfulLogin();

				onLoginFinish?.(true);
			} catch (error) {
				onLoginFinish?.(false);

				const formError = FormError.fromApolloError(error);

				if (formError?.name === 'conductorLoginRequired') {
					redirectToConductorLogin();

					return;
				}

				if (formError?.name === 'twoFactorAuthenticationRequired') {
					setView(LoginScreenView.LoginTwoFactorAuthentication);
				}

				if (formError !== undefined) {
					setError(formError);
				}
			}
		},
		[
			handleSuccessfulLogin,
			loginWithGoogleMutation,
			onLoginFinish,
			onLoginStart,
			setView,
		],
	);

	return (
		<GoogleApi>
			<GoogleSignInButton
				onClick={handleLoginWithGoogleForm}
			/>

			{error?.getName() === 'invalid_id_token' && (
				<GlobalFormMessagePattern
					style={GlobalFormMessagePatternStyle.AuthForm}
				>
					<FormattedMessage {...messages.invalidIdToken} />
				</GlobalFormMessagePattern>
			)}

			{error?.getName() === 'user_not_found' && (
				<GlobalFormMessagePattern
					style={GlobalFormMessagePatternStyle.AuthForm}
				>
					<FormattedMessage {...messages.userNotFound} />
				</GlobalFormMessagePattern>
			)}
		</GoogleApi>
	);
};



export default LoginWithGoogleForm;
