import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AuthLogo from '~/components/atoms/auth/AuthLogo';
import AuthScreenLayout from '~/components/atoms/auth/AuthScreenLayout';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import ExternalLink, {
	ExternalLinkStyle,
} from '~/components/patterns/links/ExternalLink';
import HorizontalLine from '~/components/atoms/auth/HorizontalLine';
import LoginScreenEmailSentMessage from '~/components/app/LoginScreenEmailSentMessage';
import LoginScreenPasswordResetMessage from '~/components/app/LoginScreenPasswordResetMessage';
import LoginWithCredentialsForm from '~/components/app/LoginWithCredentialsForm';
import LoginWithGoogleForm from '~/components/app/LoginWithGoogleForm';
import LoginWithTwoFactorAuthenticationForm from '~/components/app/LoginWithTwoFactorAuthenticationForm';
import RequestPasswordResetForm from '~/components/app/RequestPasswordResetForm';
import ResetPasswordForm from '~/components/app/ResetPasswordForm';
import ResetPasswordTwoFactorAuthenticationForm from '~/components/app/ResetPasswordTwoFactorAuthenticationForm';

import useUrlState from '~/hooks/useUrlState';

import {
	LoginScreenView,
	isLoginScreenView,
} from '~/model/loginScreen';

import {
	navigate,
} from '~/routing/router';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	invitationResent: {
		id: 'ui.loginScreen.invitationResent',
	},
	linkToContentKing: {
		id: 'ui.loginScreen.linkToContentKing',
	},
	lostPasswordSuccess: {
		id: 'ui.loginScreen.lostPasswordSuccess',
	},
	magicLinkResent: {
		id: 'ui.loginScreen.magicLinkResent',
	},
	or: {
		id: 'ui.general.or',
	},
	resetPasswordSuccess: {
		id: 'ui.loginScreen.resetPasswordSuccess',
	},
});



const LoginScreen: React.FC = () => {
	const urlState = useUrlState();

	const [view, setView] = React.useState(
		() => isLoginScreenView(urlState.name)
			? urlState.name as LoginScreenView
			: LoginScreenView.Login,
	);

	const [defaultEmail, setDefaultEmail] = React.useState<string | undefined>(undefined);
	const [isDoingOAuthLogin, setDoingOAuthLogin] = React.useState(false);

	const [message, setMessage] = React.useState<
		'invitationResent' | 'magicLinkResent' | null
	>(null);

	React.useEffect(
		() => {
			function removeParam(name: string) {
				const params = { ...urlState.params };
				delete params[name];

				navigate(
					urlState.name,
					params,
					{
						replace: true,
					},
				);
			}

			if (urlState.params.email_sent) {
				setMessage('magicLinkResent');
				removeParam('email_sent');
			} else if (urlState.params.invitation_resent) {
				setMessage('invitationResent');
				removeParam('invitation_resent');
			}
		},
		[urlState],
	);

	const reset = React.useCallback(
		() => {
			setDefaultEmail(undefined);
			setDoingOAuthLogin(false);
			setMessage(null);
			setView(LoginScreenView.Login);
		},
		[],
	);

	function renderContent() {
		switch (message) {

			case 'magicLinkResent':
				return (
					<LoginScreenEmailSentMessage
						message={(
							<FormattedMessage {...messages.magicLinkResent} />
						)}
						onClick={reset}
					/>
				);

			case 'invitationResent':
				return (
					<LoginScreenEmailSentMessage
						message={(
							<FormattedMessage {...messages.invitationResent} />
						)}
						onClick={reset}
					/>
				);

		}

		const renderView = matchAndReturn(view, {
			[LoginScreenView.Login]: () => (
				<DisabledContent disabledContent={isDoingOAuthLogin}>
					<LoginWithCredentialsForm
						defaultEmail={defaultEmail}
						isDisabled={isDoingOAuthLogin}
						onEmailChange={setDefaultEmail}
						setView={setView}
					/>
				</DisabledContent>
			),
			[LoginScreenView.LoginTwoFactorAuthentication]: () => (
				<LoginWithTwoFactorAuthenticationForm setView={setView} />
			),
			[LoginScreenView.PasswordLost]: () => (
				<DisabledContent disabledContent={isDoingOAuthLogin}>
					<RequestPasswordResetForm
						defaultEmail={defaultEmail}
						isDisabled={isDoingOAuthLogin}
						onEmailChange={setDefaultEmail}
						setView={setView}
					/>
				</DisabledContent>
			),
			[LoginScreenView.PasswordLostRequested]: () => (
				<LoginScreenEmailSentMessage
					message={(
						<FormattedMessage {...messages.lostPasswordSuccess} />
					)}
					onClick={reset}
				/>
			),
			[LoginScreenView.PasswordReset]: () => (
				<DisabledContent disabledContent={isDoingOAuthLogin}>
					<ResetPasswordForm
						isDisabled={isDoingOAuthLogin}
						setView={setView}
					/>
				</DisabledContent>
			),
			[LoginScreenView.PasswordResetFinished]: () => (
				<LoginScreenPasswordResetMessage
					message={(
						<FormattedMessage {...messages.resetPasswordSuccess} />
					)}
					onClick={reset}
				/>
			),
			[LoginScreenView.PasswordResetTwoFactorAuthentication]: () => (
				<DisabledContent disabledContent={isDoingOAuthLogin}>
					<ResetPasswordTwoFactorAuthenticationForm
						isDisabled={isDoingOAuthLogin}
						setView={setView}
					/>
				</DisabledContent>
			),
		});

		return renderView();
	}

	const showOAuthSection = (
		view !== LoginScreenView.LoginTwoFactorAuthentication
	);

	return (
		<AuthScreenLayout
			footer={(
				<ExternalLink
					href="https://www.contentkingapp.com"
					style={ExternalLinkStyle.Auth}
					target="_self"
				>
					<FormattedMessage {...messages.linkToContentKing} />
				</ExternalLink>
			)}
			logo={(
				<AuthLogo href="https://www.contentkingapp.com" />
			)}
		>
			{renderContent()}

			{showOAuthSection && (
				<>
					<div className="auth-page__divider">
						<HorizontalLine>
							<FormattedMessage {...messages.or} />
						</HorizontalLine>
					</div>

					<LoginWithGoogleForm
						onLoginFinish={() => setDoingOAuthLogin(false)}
						onLoginStart={() => setDoingOAuthLogin(true)}
						setView={setView}
					/>
				</>
			)}
		</AuthScreenLayout>
	);
};



export default LoginScreen;
