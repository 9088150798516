import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import LoginScreenMessage from './LoginScreenMessage';



const messages = defineMessages({
	continueToLogin: {
		id: 'ui.resetPassword.continueToLoginLink',
	},
	title: {
		id: 'ui.resetPassword.successTitle',
	},
});



type Props = {
	message: React.ReactNode,
	onClick: () => void,
};

const LoginScreenPasswordResetMessage: React.FC<Props> = (props) => {
	const {
		message,
		onClick,
	} = props;

	return (
		<LoginScreenMessage
			backButton={(
				<Button
					linkRouteName="login"
					onClick={onClick}
					size={ButtonSize.XLarge}
					style={ButtonStyle.Action}
				>
					<FormattedMessage {...messages.continueToLogin} />
				</Button>
			)}
			message={message}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		/>
	);
};



export default LoginScreenPasswordResetMessage;
