import {
	useUserHasTwoFactorAuthenticationQuery,
} from './useUserHasTwoFactorAuthentication.gql';

import useUserPropertiesQuery from '~/hooks/useUserPropertiesQuery';



function useUserHasTwoFactorAuthentication(legacyUserId: string | null): boolean | null {
	const { data } = useUserPropertiesQuery(
		useUserHasTwoFactorAuthenticationQuery,
		legacyUserId,
		{
			poll: false,
		},
	);

	return data?.user?.hasTwoFactorAuthentication ?? null;
}



export default useUserHasTwoFactorAuthentication;
