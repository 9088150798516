import React from 'react';

import {
	GOOGLE_API_CLIENT_ID,
} from '~/config';



type Props = {
	onClick: (values: {
		credential: string,
	}) => void,
};

const GoogleSignInButton: React.FC<Props> = (props) => {
	const {
		onClick,
	} = props;

	const buttonRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(
		() => {
			if (!buttonRef.current) {
				return;
			}

			window.google.accounts.id.initialize({
				client_id: GOOGLE_API_CLIENT_ID,
				callback: onClick,
			});

			window.google.accounts.id.renderButton(
				buttonRef.current,
				{},
			);
		},
		[
			onClick,
		],
	);

	return (
		<div
			ref={buttonRef}
			style={{
				margin: 'auto',
				width: '184px',
			}}
		/>
	);
};



declare global {
	interface Window {
		google: any,
	}
}



export default GoogleSignInButton;
