import {
	differenceInSeconds,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	isNumber,
	isString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	seconds: {
		id: 'ui.waitForRetry.seconds',
	},
});



type Props = {
	retryMessage?: React.ReactNode | null,
	until: Date | number | string,
};

const WaitForRetry: React.FC<Props> = (props) => {
	const {
		retryMessage = null,
		until,
	} = props;

	let date: Date;
	if (isString(until) || isNumber(until)) {
		date = new Date(until);
	} else {
		date = until;
	}

	const seconds = Math.max(differenceInSeconds(date, new Date()), 0);

	const [, forceRerender] = React.useState(0);

	React.useEffect(
		() => {
			let timeout: any;

			function scheduleRerender() {
				clearTimeout(timeout);

				timeout = setTimeout(() => {
					forceRerender(Date.now());
					scheduleRerender();
				}, 500);
			}

			scheduleRerender();

			return () => {
				clearTimeout(timeout);
			};
		},
		[
			until,
		],
	);

	if (retryMessage !== null && seconds === 0) {
		return <>{retryMessage}</>;
	}

	return (
		<FormattedMessage
			{...messages.seconds}
			values={{
				seconds,
			}}
		/>
	);
};



export default WaitForRetry;
