import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';



const messages = defineMessages({
	numberedItem: {
		id: 'ui.general.numberedItem',
	},
});



type Props = {
	content: React.ReactNode,
	number: number,
};

const NumberedItem: React.FC<Props> = (props) => {
	const {
		content,
		number,
	} = props;

	return (
		<FormattedMessage
			{...messages.numberedItem}
			values={{
				content,
				number,
			}}
		/>
	);
};



export default NumberedItem;
