import React from 'react';

import AuthButtonsLayout from '~/components/atoms/auth/AuthButtonsLayout';
import AuthFormLayout from '~/components/atoms/auth/AuthFormLayout';
import AuthFormMessage from '~/components/atoms/auth/AuthFormMessage';
import AuthTitle from '~/components/atoms/auth/AuthTitle';
import {
	type BasicIconType,
} from '~/components/patterns/icons/BasicIcon';



type Props = {
	backButton: React.ReactNode,
	iconType?: BasicIconType,
	message: React.ReactNode,
	title: React.ReactNode,
};

const LoginScreenMessage: React.FC<Props> = (props) => {
	const {
		backButton,
		iconType,
		message,
		title,
	} = props;

	return (
		<AuthFormLayout
			header={(
				<AuthTitle iconType={iconType}>
					{title}
				</AuthTitle>
			)}
		>
			<AuthFormMessage>{message}</AuthFormMessage>
			<AuthButtonsLayout leftButton={backButton} />
		</AuthFormLayout>
	);
};



export default LoginScreenMessage;
