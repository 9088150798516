import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import {
	linkInternal,
} from '~/components/logic/Copy';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useTwoFactorAuthenticationFormQuery,
} from './TwoFactorAuthenticationForm.gql';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useUserHasTwoFactorAuthentication from '~/hooks/useUserHasTwoFactorAuthentication';



const messages = defineMessages({
	active: {
		id: 'ui.twoFactorAuthenticationSettings.active',
	},
	disableLink: {
		id: 'ui.twoFactorAuthenticationSettings.disableLink',
	},
	inactive: {
		id: 'ui.twoFactorAuthenticationSettings.inactive',
	},
	label: {
		id: 'ui.twoFactorAuthenticationSettings.title',
	},
	setupLink: {
		id: 'ui.twoFactorAuthenticationSettings.setupLink',
	},
	title: {
		id: 'ui.twoFactorAuthenticationSettings.title',
	},
});



const TwoFactorAuthenticationForm: React.FC = () => {
	const currentUserId = useCurrentUserId();

	const userHasTwoFactorAuthentication = useUserHasTwoFactorAuthentication(currentUserId);

	const { data } = useTwoFactorAuthenticationFormQuery();

	const canTwoFactorAuthenticationBeDisabled = (data?.authenticatedSession?.memberships ?? []).every(
		(membership) => membership.account.isTwoFactorAuthenticationEnforced === false,
	);

	function renderDisplayPart() {
		return (
			<FormRows>
				<FormRow
					label={(
						<FormattedMessage {...messages.label} />
					)}
				>
					<StaticText focusTarget="status">
						{userHasTwoFactorAuthentication ? (
							<FormattedMessage {...messages.active} />
						) : (
							<FormattedMessage {...messages.inactive} />
						)}
					</StaticText>
				</FormRow>
			</FormRows>
		);
	}

	function renderEditablePart() {
		return (
			<Form>
				<FormRows>
					<FormRow
						htmlFor="status"
						label={(
							<FormattedMessage {...messages.label} />
						)}
					>
						{userHasTwoFactorAuthentication && (
							<StaticText>
								<FormattedMessage {...messages.active} />
								{' '}
								{canTwoFactorAuthenticationBeDisabled === false && (
									<FormattedMessage
										{...messages.disableLink}
										values={{
											openModal: linkInternal(
												'userProfile',
												{
													action: 'disable_two_factor_authentication',
												},
											),
										}}
									/>
								)}
							</StaticText>
						)}

						{!userHasTwoFactorAuthentication && (
							<StaticText>
								<FormattedMessage
									{...messages.setupLink}
									values={{
										openModal: linkInternal(
											'userProfile',
											{
												action: 'setup_two_factor_authentication',
											},
										),
									}}
								/>
							</StaticText>
						)}
					</FormRow>
				</FormRows>

				<ButtonsLayout>
					<CancelButton />
				</ButtonsLayout>
			</Form>
		);
	}

	return (
		<EditableFormWrapper
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				{renderDisplayPart()}
			</DisplayPart>

			<EditablePart>
				{renderEditablePart()}
			</EditablePart>
		</EditableFormWrapper>
	);
};

export default TwoFactorAuthenticationForm;
