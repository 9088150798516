import React from 'react';

import VerifyTwoFactorAuthenticationForm from '~/components/app/VerifyTwoFactorAuthenticationForm';

import {
	useInitiateResetPasswordWithTwoFactorAuthenticationMutation,
} from '~/components/app/ResetPasswordTwoFactorAuthenticationForm.gql';

import useResetPasswordSession from '~/hooks/usePasswordResetSession';

import {
	LoginScreenView,
} from '~/model/loginScreen';

import FormError from '~/utilities/FormError';



type Props = {
	isDisabled?: boolean,
	setView: (view: LoginScreenView) => void,
};

const ResetPasswordTwoFactorAuthenticationForm: React.FC<Props> = (props) => {
	const {
		isDisabled,
		setView,
	} = props;

	const resetPasswordSession = useResetPasswordSession();

	React.useEffect(
		() => {
			if (resetPasswordSession === null) {
				return;
			}

			if (
				resetPasswordSession.isTwoFactorAuthenticationEnabled === false
				|| resetPasswordSession.isTwoFactorAuthenticationVerified === true
			) {
				setView(LoginScreenView.PasswordReset);
			}
		},
		[
			resetPasswordSession,
			setView,
		],
	);

	const [initiateResetPasswordWithTwoFactorAuthentication] = useInitiateResetPasswordWithTwoFactorAuthenticationMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			try {
				await initiateResetPasswordWithTwoFactorAuthentication({
					variables: {
						code: values.code,
					},
				});

				setView(LoginScreenView.PasswordReset);
			} catch (error) {
				throw FormError.fromApolloError(error, {
					invalidCode: values.code,
				});
			}
		},
		[
			initiateResetPasswordWithTwoFactorAuthentication,
			setView,
		],
	);

	return (
		<VerifyTwoFactorAuthenticationForm
			isDisabled={isDisabled}
			onSubmit={handleSubmit}
			setView={setView}
		/>
	);
};



export default ResetPasswordTwoFactorAuthenticationForm;
