import React, {
	Component,
} from 'react';



class AuthFormMessage extends Component {

	render() {
		const {
			children,
		} = this.props;

		return (
			<div className="auth-form-message">
				{children}
			</div>
		);
	}

}



export default AuthFormMessage;
