import {
	useResetPasswordSessionQuery,
} from './usePasswordResetSession.gql';



function useResetPasswordSession() {
	const { data } = useResetPasswordSessionQuery();

	return data?.resetPasswordSession ?? null;
}



export default useResetPasswordSession;
