import React, {
	Component,
} from 'react';



class AuthTextSection extends Component {

	render() {
		const {
			children,
		} = this.props;

		return (
			<section className="auth-text-section">
				{children}
			</section>
		);
	}

}



export default AuthTextSection;
