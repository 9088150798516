import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import LoginScreenMessage from './LoginScreenMessage';



const messages = defineMessages({
	backToEmailAddress: {
		id: 'ui.lostPassword.backToEmailAddress',
	},
	title: {
		id: 'ui.magicLink.title',
	},
});



type Props = {
	message: React.ReactNode,
	onClick: () => void,
};

const LoginScreenEmailSentMessage: React.FC<Props> = (props) => {
	const {
		message,
		onClick,
	} = props;

	return (
		<LoginScreenMessage
			backButton={(
				<Button
					linkRouteName="login"
					onClick={onClick}
					size={ButtonSize.Medium}
					style={ButtonStyle.Link}
				>
					<FormattedMessage {...messages.backToEmailAddress} />
				</Button>
			)}
			iconType={BasicIconType.MagicWand}
			message={message}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		/>
	);
};



export default LoginScreenEmailSentMessage;
