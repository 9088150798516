import React from 'react';

import VerifyTwoFactorAuthenticationForm from '~/components/app/VerifyTwoFactorAuthenticationForm';

import {
	useLoginWithTwoFactorAuthenticationMutation,
} from './LoginWithTwoFactorAuthenticationForm.gql';

import useHandleSuccessfulLogin from '~/hooks/useHandleSuccessfulLogin';

import {
	LoginScreenView,
} from '~/model/loginScreen';

import FormError from '~/utilities/FormError';



type Props = {
	setView: (view: LoginScreenView) => void,
};

const LoginWithTwoFactorAuthenticationForm: React.FC<Props> = (props) => {
	const {
		setView,
	} = props;

	const handleSuccessfulLogin = useHandleSuccessfulLogin();
	const [loginWithTwoFactorAuthenticationMutation] = useLoginWithTwoFactorAuthenticationMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			try {
				await loginWithTwoFactorAuthenticationMutation({
					variables: {
						code: values.code,
					},
				});

				await handleSuccessfulLogin();
			} catch (error) {
				const formError = FormError.fromApolloError(error, {
					invalidCode: values.code,
				});

				const returnToLogin = [
					'loginNotInitiated',
					'passwordChangedDuringTwoFactorAuthentication',
					'twoFactorAuthenticationNotEnabled',
				].includes(formError?.name);

				if (returnToLogin) {
					setView(LoginScreenView.Login);
				} else {
					throw formError;
				}
			}
		},
		[
			handleSuccessfulLogin,
			loginWithTwoFactorAuthenticationMutation,
			setView,
		],
	);

	return (
		<VerifyTwoFactorAuthenticationForm
			onSubmit={handleSubmit}
			setView={setView}
		/>
	);
};



export default LoginWithTwoFactorAuthenticationForm;
