import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
} from 'react-redux';

import AuthButtonsLayout from '~/components/atoms/auth/AuthButtonsLayout';
import AuthFormLayout from '~/components/atoms/auth/AuthFormLayout';
import AuthTextSection from '~/components/atoms/auth/AuthTextSection';
import AuthTitle from '~/components/atoms/auth/AuthTitle';
import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import {
	ButtonSize,
} from '~/components/patterns/buttons/Button';
import Form from '~/components/atoms/forms/basis/Form';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useFinishInvitationWithTwoFactorAuthenticationLazyQuery,
} from '~/components/app/AcceptInvitationWith2FAConfirmation.gql';

import {
	tryLoadUserData,
} from '~/model/authentication';

import {
	navigate,
} from '~/routing/router';



const messages = defineMessages({
	button: {
		id: 'ui.twoFactorAuthenticationSetupBeforeJoining.success.button',
	},
	content: {
		id: 'ui.twoFactorAuthenticationSetupBeforeJoining.success.content',
	},
	title: {
		id: 'ui.twoFactorAuthenticationSetupBeforeJoining.success.title',
	},
});



const AcceptInvitationWith2FAConfirmation: React.FC = () => {
	const dispatch = useDispatch();

	const [finishInvitationWithTwoFactor] = useFinishInvitationWithTwoFactorAuthenticationLazyQuery();

	const handleSubmit = React.useCallback(
		async () => {
			await finishInvitationWithTwoFactor();

			await dispatch(tryLoadUserData());

			navigate('home');
		},
		[
			dispatch,
			finishInvitationWithTwoFactor,
		],
	);

	return (
		<AuthFormLayout
			header={(
				<AuthTitle iconType={BasicIconType.Protected}>
					<FormattedMessage {...messages.title} />
				</AuthTitle>
			)}
		>
			<AuthTextSection>
				<RichText>
					<p>
						<FormattedMessage {...messages.content} />
					</p>
				</RichText>
			</AuthTextSection>

			<Form
				defaultDataHasChanged={true}
				onSuccess={handleSubmit}
			>
				<AuthButtonsLayout
					leftButton={(
						<SubmitButton
							size={ButtonSize.XLarge}
						>
							<FormattedMessage {...messages.button} />
						</SubmitButton>
					)}
				/>
			</Form>
		</AuthFormLayout>
	);
};



export default AcceptInvitationWith2FAConfirmation;
