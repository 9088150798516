import React, {
	Component,
} from 'react';



class HorizontalLine extends Component {

	render() {
		const {
			children,
		} = this.props;

		const childrenCount = React.Children.count(children);

		return (
			<div className="auth-horizontal-line">
				{childrenCount > 0 && (
					<div className="auth-horizontal-line__description">
						{children}
					</div>
				)}
			</div>
		);
	}

}



export default HorizontalLine;
