import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import AcceptInvitationWith2FAConfirmation from '~/components/app/AcceptInvitationWith2FAConfirmation';

import AcceptInvitationWith2FASetup from '~/components/app/AcceptInvitationWith2FASetup';
import AuthLogo from '~/components/atoms/auth/AuthLogo';
import AuthScreenLayout from '~/components/atoms/auth/AuthScreenLayout';
import ExternalLink, {
	ExternalLinkStyle,
} from '~/components/patterns/links/ExternalLink';



const messages = defineMessages({
	whatIs2FA: {
		id: 'ui.twoFactorAuthenticationSetupBeforeJoining.whatIs2FA',
	},
});



const AcceptInvitationWith2FAScreen: React.FC = () => {
	const [isSetupCompleted, setSetupCompleted] = React.useState(false);

	return (
		<AuthScreenLayout
			footer={(
				<ExternalLink
					href="https://www.contentkingapp.com/support/two-factor-authentication/"
					style={ExternalLinkStyle.Auth}
				>
					<FormattedMessage {...messages.whatIs2FA} />
				</ExternalLink>
			)}
			logo={(
				<AuthLogo />
			)}
		>
			{isSetupCompleted === false && (
				<AcceptInvitationWith2FASetup
					onFinish={() => setSetupCompleted(true)}
				/>
			)}

			{isSetupCompleted === true && (
				<AcceptInvitationWith2FAConfirmation />
			)}

		</AuthScreenLayout>
	);
};



export default AcceptInvitationWith2FAScreen;
